import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import api from '../api';
import { toast } from 'react-toastify';
import store from "../redux/store";


class DefaultFooter extends Component {

	constructor(props) {
		super(props);

		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			email: '',
		}
	}

	onChangeEmail(e) {
		this.setState({
			email: e.target.value
		})
	}

	async onSubmit(e) {
		e.preventDefault();

		// const listId = process.env.REACT_APP_MAILCHIMP_AUDIENCE_ID;  const API_KEY = process.env.REACT_APP_MAILCHIMP_API_KEY; const region = process.env.REACT_APP_MAILCHIMP_REGION;

		const data = {
			email_address: this.state.email,
			status: 'subscribed'
		}

		// await new Promise((resolve, reject) => { 
		// axios({ method: 'post', url: `https://${region}.api.mailchimp.com/3.0/lists/${listId}/members/`, 
		// headers: { Accept: 'application/json', Authorization: `Basic ${API_KEY}`, }, 
		// data, })
		//   .then(res => {
		//     if (res.result === 'success') {
		//       toast.success('Thank you for subscribing!');
		//     } else {
		//       toast.success('An error occurred! The email might have been subscribed before or is not entered correctly');
		//     }
		//   }).catch(error => {
		//     let err = store.getState().error;
		//     if (err) {
		//       toast.error(err.message);
		//       this.props.history.push(err.redirectUrl)
		//     }
		//     console.log(error)
		//   }); 
		//   (err, response, body) => { if (err) { reject(err); } else { resolve(body); } },); 
		// });

		api.post(`/newsletter-subscribe`, data)
			.then(res => {
				if (res.data.status) {
					toast.success('Thank you for subscribing!');
					this.setState({
						email: ""
					})
				} else {
					toast.error('An error occurred! The email might have been subscribed before or is not entered correctly');
				}
			}).catch(error => {
				let err = store.getState().error;
				if (err) {
					toast.error(err.message);
					this.props.history.push(err.redirectUrl)
				}
				console.log(error)
			});
	}

	render() {
		return (
			<div className='footer-div'>

				<footer className="footer footer-1">
					<div className="footer-top  pt-70">
						<div className="container">
							<div className="row">

								<div className="col-xl-4 col-lg-3 col-xs-6 col-12 pr-lg-90">
									<div className="footer-widget mb-30 wow fadeInLeft">
										<div className="flog mb-35">
											<a href="index-2.html"><img src={require("../../dassets/img/logo/Logo-2.jpg")} height="80" alt="logo" /></a>
										</div>
										<div className="social-icon mt-5">
											<a target={"_blank"} href="https://www.facebook.com/DynamicPortfolioFinance"><i className="fab fa-facebook-f"></i></a>
											<a target={"_blank"} href="https://twitter.com/DynamicPFinance"><i className="fab fa-twitter"></i></a>
											<a target={"_blank"} href="https://www.instagram.com/dynamicfinance/"><i className="fab fa-instagram"></i></a>
											<a target={"_blank"} href="#"><i className="fab fa-linkedin"></i></a>
											<a target={"_blank"} href="https://api.whatsapp.com/send/?phone=%2B2349010482467&text=Hello&app_absent=0"><i className="fab fa-whatsapp"></i></a>
										</div>
									</div>
								</div>


								<div className="col-xl-3 col-lg-3 col-xs-6 col-12">
									<div className="footer-widget mb-30 wow fadeInUp" data-wow-delay="0.1s">
										<div className="f-widget-title">
											<h5>Location</h5>
										</div>
										<div className="footer-text">
											<p>
												<span>Dynamic Portfolio House,<br />Penthouse & 2nd Floor,<br /> 20, Campbell Street,<br /> Lagos Island, Lagos, Nigeria.</span></p>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-2 col-6">
									<div className="footer-widget mb-30 wow fadeInUp" data-wow-delay="0.3s">
										<div className="f-widget-title">
											<h5>Company</h5>
										</div>
										<div className="footer-link">
											<ul>
												<li><Link to="/about"> About Us</Link></li>
												<li><Link to="/contact"> Contact us</Link></li>
												<li><Link to="/faq"> FAQs</Link></li>
												<li><Link to="/careers"> Careers</Link></li>
											</ul>
										</div>
									</div>

								</div>
								<div className="col-xl-2 col-lg-2 col-6">
									<div className="footer-widget mb-30 wow fadeInUp" data-wow-delay="0.5s">
										<div className="f-widget-title">
											<h5>Services</h5>
										</div>
										<div className="footer-link">
											<ul>
												<li><Link to="/service/loan">Loan</Link></li>
												<li><Link to="/service/lease">Lease</Link></li>
												<li><Link to="/service/fund-management">Fund Management</Link></li>
												<li><Link to={"/service/lpo-financing"}>LPO Financing</Link></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="copyright py-4">
						<div className="container-lg container-fluid">
							<div className="row align-items-center ">
								<div className="col-lg-3 text-center">
									<div className="copyright-text short-text">
										<p>Copyright &copy; 2022, All Rights Reserved</p>
									</div>
								</div>
								<div className="col-lg-5 text-center short-text">
									<a href="/terms-conditions">Terms & Conditions </a>
									{/* <a href="#">Privacy Policy</a> */}
									{/* <a href="#">Legal Notice</a> */}
								</div>
								<div className="col-lg-3 text-center text-lg-end">
									<div className="copyright-text short-text">
										<p>Designed & Developed by <a target="_blank" href="https://setsofttech.com">Setsoft</a></p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</footer>





				{/* <form className="form-inline input-with-btn" onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <input type="email" className="form-control" value={this.state.email} onChange={this.onChangeEmail} placeholder="Enter your email" />
                    </div>
                    <button type="submit" className="btn btn-primary">Subscribe</button>
                  </form> */}
			</div>
		);
	}
}

export default DefaultFooter;