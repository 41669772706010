import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import api from '../api';
import moment from 'moment';
import { toast } from 'react-toastify';
import store from "../redux/store";

class DefaultNavbar extends Component {
    // constructor(props) {
    //   super(props);

    //   this.state = {
    //     prices: [],
    //   }
    // }

    render() {
        return (
            <>
                <header className="header">
                    <div className="header-top py-2">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="header-info-left">
                                        <div className="timestamp ms-4"> <i className="icon_clock_alt"></i> Mon - Fri 8:00am-6:00pm
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="header-info-right">
                                        <ul>
                                            <li>
                                            <i className="icon_phone"></i><a
                                                    href="tel:08055550606">+(234) 0805 555 0606</a>
                                            </li>

                                            <li>
                                                <i className="icon_mail_alt"></i><a
                                                    href="mailto:info@dynamicportfoliofinance.com">info@dynamicportfoliofinance.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-menu header-menu-2" id="sticky">
                        <nav className="navbar navbar-expand-lg ">
                            <div className="container">
                                <Link className="navbar-brand" to={"/"}>
                                    <img src={require("../../dassets/img/logo/Logo-2.jpg")} height="80" alt="logo" />
                                </Link>
                                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="menu_toggle">
                                        <span className="hamburger">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                        <span className="hamburger-cross">
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </span>
                                </button>

                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav menu ms-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/"}><span>Home</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/about"}><span>About Us</span></Link>
                                        </li>
                                        <li className="nav-item dropdown submenu ">
                                            <a href="#" className="nav-link dropdown-toggle" role="button"
                                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services</a>
                                            <i className="arrow_carrot-down_alt2 mobile_dropdown_icon" aria-hidden="true"
                                                data-bs-toggle="dropdown"></i>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={"/service/loan"}><span>Loan</span></Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={"/service/lease"}><span>Lease</span></Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={"/service/fund-management"}><span>Fund Management</span></Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={"/service/lpo-financing"}><span>LPO Financing</span></Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/faqs"}><span>FAQs</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/contact"}><span>Contact us</span></Link>
                                        </li>
                                    </ul>
                                    {/* <a className="theme-btn" href="#">Get loan</a> */}
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            </>
        );
    }
}

export default DefaultNavbar;