
import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute, UnAuthRoute, LRoute } from './RouteMiddleware';

import Navbar from './shared/Navbar';
import DefaultNavbar from './shared/default-navbar';
import DefaultFooter from './shared/default-footer';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';

import Spinner from '../app/shared/Spinner';
// import { render } from 'nprogress';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Error404 = lazy(() => import('./user-pages/Error404'));
const Error500 = lazy(() => import('./user-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const ForgotPassword = lazy(() => import('./user-pages/forgot-password'));
const ResetPassword = lazy(() => import('./user-pages/reset-password'));
const Logout = lazy(() => import('./user-pages/Logout'));

// departments
const ListDepartments = lazy(() => import('./departments/list'));
const CreateDepartment = lazy(() => import('./departments/create'));
const EditDepartment = lazy(() => import('./departments/edit'));
const DepartmentDetails = lazy(() => import('./departments/details'));

// jobs
const ListJobs = lazy(() => import('./jobs/list'));
const CreateJob = lazy(() => import('./jobs/create'));
const EditJob = lazy(() => import('./jobs/edit'));
const JobDetails = lazy(() => import('./jobs/details'));

// ngx
// const ListNgx = lazy(() => import('./ngxindex/list'));
// const CreateNgx = lazy(() => import('./ngxindex/create'));
// const EditNgx = lazy(() => import('./ngxindex/edit'));
// const NgxDetails = lazy(() => import('./ngxindex/details'));

// documents
// const ListDocuments = lazy(() => import('./documents/list'));
// const CreateDocuments = lazy(() => import('./documents/create'));
// const EditDocument = lazy(() => import('./documents/edit'));
// const DocumentDetails = lazy(() => import('./documents/details'));

// designations
const ListDesignations = lazy(() => import('./designations/list'));
const CreateDesignation = lazy(() => import('./designations/create'));
const EditDesignation = lazy(() => import('./designations/edit'));
const DesignationDetails = lazy(() => import('./designations/details'));

// contents
const ListContent = lazy(() => import('./contents/list'));
const CreateContent = lazy(() => import('./contents/create'));
const EditContent = lazy(() => import('./contents/edit'));
const ContentDetails = lazy(() => import('./contents/details'));

// Users
const ListUsers = lazy(() => import('./users/list'));
const CreateUser = lazy(() => import('./users/create'));
const EditUser = lazy(() => import('./users/edit'));
const UserDetails = lazy(() => import('./users/details'));
const EditUserSelf = lazy(() => import('./users/user-edit'));
const UserDetailsSelf = lazy(() => import('./users/user-details'));
const ChangePassword = lazy(() => import('./users/change-password'));

// Loans
const Loan = lazy(() => import('./pages/loan'));
const ListLoans = lazy(() => import('./loans/list'));
const CreateLoan = lazy(() => import('./loans/create'));
const LoanDetails = lazy(() => import('./loans/details'));

// LPO Financing
const ListLPOs = lazy(() => import('./lpo-financing/list'));
const CreateLPO = lazy(() => import('./lpo-financing/create'));
const LPODetails = lazy(() => import('./lpo-financing/details'));

// Leases
const Lease = lazy(() => import('./pages/lease'));
const ListLeases = lazy(() => import('./leases/list'));
const CreateLease = lazy(() => import('./leases/create'));
const LeaseDetails = lazy(() => import('./leases/details'));

// Deposits
const FundManagement = lazy(() => import('./pages/fund_management'));
const ListDeposits = lazy(() => import('./fund_deposit/list'));
const CreateDeposit = lazy(() => import('./fund_deposit/create'));
const DepositDetails = lazy(() => import('./fund_deposit/details'));

// Withdrawals
const ListWithdrawals = lazy(() => import('./fund_withdrawal/list'));
const CreateWithdrawal = lazy(() => import('./fund_withdrawal/create'));
const WithdrawalDetails = lazy(() => import('./fund_withdrawal/details'));

const BlankPage = lazy(() => import('./user-pages/BlankPage'));

const Home = lazy(() => import('./pages/home'));
const About = lazy(() => import('./pages/about'));
const Terms = lazy(() => import('./pages/terms'));
const Contact = lazy(() => import('./pages/contact'));
// const DynamicNano = lazy(() => import('./pages/dynamic_nano'));
const Faqs = lazy(() => import('./pages/faqs'));
const Careers = lazy(() => import('./pages/careers'));
const LPOFinancing = lazy(() => import('./pages/lpo-financing'));


const DefaultLayout = (props) => {
  require('./DApp.scss');
  require('./App.css');
  return (
    <div className="dpf">
      <DefaultNavbar />
      <div className='dpfbody'>
        {props.children}
      </div>
      <DefaultFooter />
    </div>
  )
}


const AdminLayout = (props) => {
  require('./App.scss');
  require('./App.css');
  return (
    <div className='admin'>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper admin">
              {props.children}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}


const UnAuthLayout = (props) => {
  require('./App.scss');
  require('./App.css');
  return (
    <div className='admin'>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


class AppRoutes extends Component {

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>

          <PrivateRoute layout={UnAuthLayout} path="/logout" component={Logout} />

          <PrivateRoute exact layout={AdminLayout} path="/dashboard" component={Dashboard} />

          {/* Documents */}
          {/* <PrivateRoute exact layout={AdminLayout} path="/documents" component={ListDocuments} />
          <PrivateRoute exact layout={AdminLayout} path="/documents/create" component={CreateDocuments} />
          <PrivateRoute exact layout={AdminLayout} path="/documents/edit/:id" component={EditDocument} />
          <PrivateRoute exact layout={AdminLayout} path="/documents/:id" component={DocumentDetails} /> */}

          {/* Departments */}
          <PrivateRoute permission={{ key: "department", value: "read" }} exact layout={AdminLayout} path="/departments" component={ListDepartments} />
          <PrivateRoute permission={{ key: "department", value: "create" }} exact layout={AdminLayout} path="/departments/create" component={CreateDepartment} />
          <PrivateRoute permission={{ key: "department", value: "update" }} exact layout={AdminLayout} path="/departments/edit/:id" component={EditDepartment} />
          <PrivateRoute permission={{ key: "department", value: "read" }} exact layout={AdminLayout} path="/departments/:id" component={DepartmentDetails} />

          {/* Jobs */}
          <PrivateRoute exact layout={AdminLayout} path="/jobs" component={ListJobs} />
          <PrivateRoute exact layout={AdminLayout} path="/jobs/create" component={CreateJob} />
          <PrivateRoute exact layout={AdminLayout} path="/jobs/edit/:id" component={EditJob} />
          <PrivateRoute exact layout={AdminLayout} path="/jobs/:id" component={JobDetails} />

          {/* Jobs */}
          <PrivateRoute exact layout={AdminLayout} path="/contents" component={ListContent} />
          <PrivateRoute exact layout={AdminLayout} path="/contents/create" component={CreateContent} />
          <PrivateRoute exact layout={AdminLayout} path="/contents/edit/:id" component={EditContent} />
          <PrivateRoute exact layout={AdminLayout} path="/contents/:id" component={ContentDetails} />

          {/* Designations */}
          <PrivateRoute permission={{ key: "designation", value: "read" }} exact layout={AdminLayout} path="/designations" component={ListDesignations} />
          <PrivateRoute permission={{ key: "designation", value: "create" }} exact layout={AdminLayout} path="/designations/create" component={CreateDesignation} />
          <PrivateRoute permission={{ key: "designation", value: "update" }} exact layout={AdminLayout} path="/designations/edit/:id" component={EditDesignation} />
          <PrivateRoute permission={{ key: "designation", value: "read" }} exact layout={AdminLayout} path="/designations/:id" component={DesignationDetails} />

          {/* Users */}
          <PrivateRoute permission={{ key: "user", value: "read" }} exact layout={AdminLayout} path="/users" component={ListUsers} />
          <PrivateRoute permission={{ key: "user", value: "create" }} exact layout={AdminLayout} path="/users/create" component={CreateUser} />
          <PrivateRoute permission={{ key: "user", value: "update" }} exact layout={AdminLayout} path="/users/edit/:id" component={EditUser} />
          <PrivateRoute permission={{ key: "user", value: "read" }} exact layout={AdminLayout} path="/users/:id" component={UserDetails} />
          <PrivateRoute exact layout={AdminLayout} path="/user/edit" component={EditUserSelf} />
          <PrivateRoute exact layout={AdminLayout} path="/user" component={UserDetailsSelf} />
          <PrivateRoute exact layout={AdminLayout} path="/change-password" component={ChangePassword} />

          {/* Loans */}
          <PrivateRoute exact layout={AdminLayout} path="/loans" component={ListLoans} />
          <LRoute exact layout={DefaultLayout} path="/loans/apply" component={CreateLoan} />
          <PrivateRoute exact layout={AdminLayout} path="/loans/view" component={LoanDetails} />

          {/* LPO FInancing */}
          <PrivateRoute exact layout={AdminLayout} path="/lpo-financing" component={ListLPOs} />
          <LRoute exact layout={DefaultLayout} path="/lpo-financing/apply" component={CreateLPO} />
          <PrivateRoute exact layout={AdminLayout} path="/lpo-financing/view" component={LPODetails} />

          {/* Leases */}
          <PrivateRoute exact layout={AdminLayout} path="/leases" component={ListLeases} />
          <LRoute exact layout={DefaultLayout} path="/leases/apply" component={CreateLease} />
          <PrivateRoute exact layout={AdminLayout} path="/leases/view" component={LeaseDetails} />

          {/* Funds Deposit */}
          <PrivateRoute exact layout={AdminLayout} path="/fund-deposits" component={ListDeposits} />
          <LRoute exact layout={DefaultLayout} path="/fund-deposits/apply" component={CreateDeposit} />
          <PrivateRoute exact layout={AdminLayout} path="/fund-deposits/view" component={DepositDetails} />

          {/* Funds Withdrawal */}
          <PrivateRoute exact layout={AdminLayout} path="/fund-withdrawals" component={ListWithdrawals} />
          <LRoute exact layout={DefaultLayout} path="/fund-withdrawals/apply" component={CreateWithdrawal} />
          <PrivateRoute exact layout={AdminLayout} path="/fund-withdrawals/view" component={WithdrawalDetails} />


          <UnAuthRoute path="/login" layout={UnAuthLayout} component={Login} />
          <UnAuthRoute path="/forgot-password" layout={UnAuthLayout} component={ForgotPassword} />
          <UnAuthRoute path="/reset-password" layout={UnAuthLayout} component={ResetPassword} />
          <LRoute path="/404" layout={UnAuthLayout} component={Error404} />
          <LRoute path="/500" layout={UnAuthLayout} component={Error500} />

          <LRoute path="/user-pages/blank-page" layout={AdminLayout} component={BlankPage} />

          <LRoute exact layout={DefaultLayout} path="/service/lease" component={Lease} />
          <LRoute exact layout={DefaultLayout} path="/service/loan" component={Loan} />
          <LRoute exact layout={DefaultLayout} path="/service/fund-management" component={FundManagement} />
          <LRoute exact layout={DefaultLayout} path="/service/lpo-financing" component={LPOFinancing} />
          <LRoute path="/about" layout={DefaultLayout} component={About} />
          <LRoute path="/contact" layout={DefaultLayout} component={Contact} />
          <LRoute path="/faqs" layout={DefaultLayout} component={Faqs} />
          <LRoute path="/careers" layout={DefaultLayout} component={Careers} />
          <LRoute path="/terms-conditions" layout={DefaultLayout} component={Terms} />
          {/* <LRoute path="/dynamic-nano" layout={DefaultLayout} component={DynamicNano} /> */}
          <LRoute path="/" layout={DefaultLayout} component={Home} />

          <Redirect to="/404" />
        </Switch>
      </Suspense>
    );
  }

}

export default AppRoutes;